import Spline from '@splinetool/react-spline';


const RocketHireSpline = () => {
  return (
    <div>
    <div>
      <div className="relative w-full h-[35vh] md:h-[70vh] lg:h-[50vh] xl:h-[90vh] 2xl:h-[60vh]">
        <Spline scene="https://prod.spline.design/QUhMQ0mdWJAp6SkK/scene.splinecode" />

      </div>
    </div>

 {/*     <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 inset-0 bg-[#ffffff1f]">

        <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
          <div className="lg:w-1/2">
            <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-black sm:text-4xl sm:leading-none xl:max-w-lg ">
              The quick, brown fox jumps over a lazy dog
            </h2>
          </div>
          <div className="lg:w-1/2 z-10">
            <p className="text-base text-white md:text-lg">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque rem aperiam, eaque ipsa quae. Sed ut unde
              omnis iste natus.
            </p>
          </div>
        </div>


        <div className="grid gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-4">

          <div className="z-10 max-w-md bg-[#d6d6d600] py-8 px-2 text rounded-md shadow-xl shadow-[#2e2e2e] bg-gradient-to-r from-gray-700   to-gray-800">

            <h6 className="mb-2 font-semibold leading-5 text-white">The deep ocean</h6>
            <p className="mb-3 text-sm text-white">
              A flower in my garden, a mystery in my panties. Heart attack never
              stopped old Big Bear. I didn't even know we were calling him Big
              Bear.
            </p>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a>
          </div>



          <div className="z-10 max-w-md bg-[#d6d6d600] py-8 px-2 text rounded-md shadow-xl shadow-[#2e2e2e] bg-gradient-to-r from-gray-700   to-gray-800">
            <h6 className="mb-2 font-semibold5 leading-5 text-white">When has justice</h6>
            <p className="mb-3 text-sm text-white">
              Rough pomfret lemon shark plownose chimaera southern sandfish
              kokanee northern sea robin Antarctic cod.
            </p>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a>
          </div>



          <div className="z-10 max-w-md bg-[#d6d6d600] py-8 px-2 text rounded-md shadow-xl shadow-[#2e2e2e] bg-gradient-to-r from-gray-700   to-gray-800">
            <h6 className="mb-2 font-semibold leading-5 text-white">Organically grow</h6>
            <p className="mb-3 text-sm text-white">
              A slice of heaven. O for awesome, this chocka full cuzzie is as
              rip-off as a cracker.
            </p>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a>
          </div>


          
          <div className="z-10 max-w-md bg-[#d6d6d600] py-8 px-2 text rounded-md shadow-xl shadow-[#2e2e2e] bg-gradient-to-r from-gray-700   to-gray-800">
            <h6 className="mb-2 font-semibold leading-5 text-white">A slice of heaven</h6>
            <p className="mb-3 text-sm text-white">
              Disrupt inspire and think tank, social entrepreneur but preliminary
              thinking think tank compelling.
            </p>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a>
          </div>

        </div>
      </div>*/}
    </div>
    
  );
};

export default RocketHireSpline