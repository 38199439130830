import library from './Images/library.jpg'
import magnifyingglass from './Images/magnifyingglass.jpg'
import hike from './Images/hike.jpg'
import hiringmanager from './Images/hiringmanager.jpg'


function Value() {



    return (
      <div className="bg-[#ffffff00]">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-center text-base/7 font-semibold text-indigo-600"></h2>
          <p className="mx-auto mt-2 max-w-lg text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl">
            Let's talk Value...
          </p>
          <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2 sm:h-[600px]">
            <div className="relative lg:row-span-2">
              <div className="absolute inset-px rounded-lg  bg-white lg:rounded-l-[2rem] border border-gray-400"></div>
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-rockethire-orange max-lg:text-center">
                    Value from Day 1
                  </p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-800 max-lg:text-center">
                    From the start, we provide you with a job application tracker, personalized 
                    coaching, and an optimized resume and LinkedIn profile. 
                  </p>
                  <p className="mt-2 mb-2max-w-lg text-sm/6 text-gray-800 max-lg:text-center">
                    Our tools and guidance ensure you're prepared to stand out and succeed 
                    in your job search.                  
                  </p>
                </div>
                <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                  <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-3xl border-x-[1cqw] border-t-[1cqw] border-gray-700 bg-gray-900 shadow-2xl">
                    <img
                      className="size-full object-cover object-top"
                      src={library}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
            </div>
            <div className="relative max-lg:row-start-1">
              <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] border border-gray-400"></div>
              <div className=" relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
                <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-rockethire-orange max-lg:text-center">Find All the Hidden Jobs</p>
                  <p className="mt-2 mb-3 max-w-lg text-sm/6 text-gray-800 max-lg:text-center">
                    We go beyond traditional job boards, searching across 30+ platforms and 
                    uncovering hidden opportunities that others miss, using advanced search
                    techniques and industry expertise. 
                  </p>
                </div>
                <div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                  <img
                    className="w-full max-lg:max-w-xs rounded-3xl border-2 border-gray-700"
                    src={magnifyingglass}
                    alt=""
                  />
                </div>
              </div>
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
            </div>
            <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
              <div className="absolute inset-px rounded-lg bg-white border border-gray-400"></div>
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-rockethire-orange max-lg:text-center">Your Partner Every Step of the Way</p>
                  <p className="mt-2 mb-2 max-w-lg text-sm/6 text-gray-800 max-lg:text-center">
                    You're never alone in your job search. We provide weekly updates, 
                    daily market reviews, and continuous support to keep you on track 
                    and motivated.
                  </p>
                </div>
                <div className="flex flex-1 items-center [container-type:inline-size] max-lg:py-6 lg:pb-2">
                  <img
                    className="object-cover rounded-3xl border-2  border-gray-700"
                    src={hike}
                    alt=""
                  />
                </div>
              </div>
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
            </div>
            <div className="relative lg:row-span-2">
              <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-r-[2rem] border border-gray-400"></div>
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                  <p className="mt-2 text-lg font-medium tracking-tight text-rockethire-orange max-lg:text-center">
                    Recruiters Who Know Both Sides
                  </p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-800 max-lg:text-center">
                    With over 7 years of experience and 1,200+ professionals placed, we know 
                    exactly what recruiters look for. Our team understands ATS intricacies 
                    and how to position you as the ideal candidate for any role.
                  </p>
                </div>
                <div className="relative min-h-[30rem] w-full grow">
                  <div className="absolute bottom-0 left-10 right-0 top-10 overflow-hiddenbg-gray-900 shadow-2xl">
                  <img
                    className="object-cover rounded-3xl border-2 border-gray-700"
                    src={hiringmanager}
                    alt=""
                  />
                    
                    {/*<div className="flex bg-gray-800/40 ring-1 ring-white/5">
                      <div className="-mb-px flex text-sm/6 font-medium text-gray-400">
                        <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                          NotificationSetting.jsx
                        </div>
                        <div className="border-r border-gray-600/10 px-4 py-2">App.jsx</div>
                      </div>
                    </div>
                    <div className="px-6 pb-14 pt-6">{/* Your code example </div>*/}

                    
                  </div>
                </div>
              </div>
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default Value;