import { useState } from "react";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none text-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium mx-auto">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-500 transition-transform duration-200 ${
              isOpen ? 'transform rotate-180' : ''
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="bg-[#ffffff00]">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col mb-16 sm:text-center">
            <div className="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                <span className="relative inline-block">
                  <span className="relative">If you have any questions,</span>
                </span>{' '}
                we have the <span className="text-rockethire-orange">Answers</span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                If you have any other questions not listed here, you can always reach us at <span className="text-rockethire-orange font-semibold">management@rockethire.ca</span> and we will answer right away.
              </p>
            </div>
          </div>
          <div className="space-y-4 text-center">
            <Item title="What do our services include?">
              <ul className="py-2">
                <li><span className="text-rockethire-orange font-semibold">Resume building</span> and optimization for ATS compliance.</li>
                <li><span className="text-rockethire-orange font-semibold">LinkedIn profile enhancement</span> for visibility and credibility.</li>
                <li><span className="text-rockethire-orange font-semibold">Job search</span> and application management on 30+ job boards.</li>
                <li><span className="text-rockethire-orange font-semibold">Application tracking</span> and status updates.</li>
                <li><span className="text-rockethire-orange font-semibold">Mock interviews</span> and interview preparation (Elite Plan).</li>
              </ul>
            </Item>

            <Item title="How does WeApply simplify the job search process?">
              <p className="py-2">
                We handle everything from creating accounts, tailoring applications, and applying to jobs on your behalf to providing tools like <span className="text-rockethire-orange font-semibold">application trackers</span> and <span className="text-rockethire-orange font-semibold">mock interview sessions</span>. This allows you to focus solely on preparing for interviews and landing your dream role.
              </p>
            </Item>

            <Item title="How do you choose the right jobs for me?">
              <p className="py-2">
                We use a combination of <span className="text-rockethire-orange font-semibold">advanced algorithms</span> and manual curation to match your <span className="text-rockethire-orange font-semibold">skills</span>, <span className="text-rockethire-orange font-semibold">career goals</span>, and preferences with roles on over 30 job boards. Every role is aligned with your desired <span className="text-rockethire-orange font-semibold">industries</span> and <span className="text-rockethire-orange font-semibold">locations</span>.
              </p>
            </Item>

            <Item title="How long does it take to see results?">
              <p className="py-2">
                Results depend on factors like your industry and the job market. However, we aim to start <span className="text-rockethire-orange font-semibold">applying to jobs</span> within the first week of onboarding. You’ll also receive regular updates on <span className="text-rockethire-orange font-semibold">application statuses</span> and potential <span className="text-rockethire-orange font-semibold">interview requests</span>.
              </p>
            </Item>

            <Item title="How do you keep me informed throughout the process?">
              <p className="py-2">
                We provide a <span className="text-rockethire-orange font-semibold">dedicated application tracker</span>, updated in real-time, where you can monitor the status of your applications. You’ll also receive email or Slack updates about <span className="text-rockethire-orange font-semibold">interview requests</span>, application statuses, and next steps.
              </p>
            </Item>

            <Item title="What if I need help after getting a job?">
              <p className="py-2">
                We offer <span className="text-rockethire-orange font-semibold">ongoing support</span> even after you secure a role, including assistance with negotiating <span className="text-rockethire-orange font-semibold">salary</span> and <span className="text-rockethire-orange font-semibold">benefits</span>, onboarding tips, and discounted services for future job searches.
              </p>
            </Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
