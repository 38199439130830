import React from 'react'
import StepbyStep from '../components/WeApply/StepbyStep/StepbyStep.jsx';
import FAQ from './WeApply/FAQ/FAQ.jsx';
import RocketHireSpline from './Body/RocketHireSpline.jsx';
import { Parallax, ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import TopBanner from './Body/TopBanner.jsx';
import RolesWeHire from './Body/RolesWeHire.jsx';
import CalculatorComponent from './Calculator/Calculator.jsx';
import Testimonials from './WeApply/Testimonials/Testimonials.jsx';
import ThreeBoxBanner from '../components/WeApply/ThreeBoxBanner/ThreeBoxBanner.jsx'
import spacebanner from '../assets/spacebanner2.jpg'
import AnimatedListItem from './WeApply/AnimatedListItem/AnimatedListItem.jsx';
import ServicesinDetail from './WeApply/ServicesinDetail/ServicesinDetail.jsx';
import BentoGridComponent from './WeApply/BentoGrid/BentoGridComponent.jsx';
import HowItWorks from './WeApply/HowItWorks/HowItWorks.jsx'
import AnimatedPinDemo from './WeApply/StatsMainPage/AnimatedPinDemo'
import Value from './WeApply/Value/Value.jsx'
import Pricing from './WeApply/Pricing/Pricing.jsx'
import TestimonialText from './WeApply/Testimonials/TestimonialText.jsx'
import JobBoards from './WeApply/JobBoards/JobBoards.jsx';







const FullApp = () => {


  return (
    <div>



          <div className=''>
            <RocketHireSpline/>
          </div>
          

        <ParallaxProvider>
          <Parallax speed={-20}>
            <div>
              <ThreeBoxBanner/>
            </div>
          </Parallax>
        </ParallaxProvider>
           
        <ParallaxProvider>
          <Parallax speed={0}>          
            <div>
              <HowItWorks/>
            </div>
          </Parallax>
        </ParallaxProvider>

        <ParallaxProvider>
          <Parallax speed={20}>   
            <div>
              <AnimatedPinDemo/>
            </div>
          </Parallax>
        </ParallaxProvider>


        <ParallaxProvider>
          <Parallax speed={3}>   
            <div>
              <Value/>
              <JobBoards/>
            </div>
          </Parallax>
        </ParallaxProvider>



        <ParallaxProvider>
          <Parallax speed={-15}>
            <div>
              <Pricing/>
              <TestimonialText/>
            </div>
          </Parallax>
        </ParallaxProvider>




{/*
            <div>
              <AnimatedListItem/>
            </div>






        <div>
          <ServicesinDetail/>
        </div>



          <div>
            <RolesWeHire/>
          </div>

          <div id='stepbystep'>
            <StepbyStep/>
          </div>


                 



          <div className="!p-0 !m-0 ">

            <Testimonials/>
          </div>
           */}

{/*

          <div id='calculator'>
            <CalculatorComponent/>
          </div>




          <div>
            <WhatWeBring/>
          </div>


          <div id='aboutus'>
            <AboutUs/>
          </div>



          <div>
            <BookACall/>
          </div>

          

{/*

        <div>
          <ManatalJobsApply/>
        </div>
*/}

        <div id='faq'>
          <FAQ/>
        </div>


      
    </div>
  )
}
/*        <div className='absolute inset-0 blur-[258px] max-w-lg h-[1800px] mx-auto sm:max-w-3xl sm:h-[1400px]' style={{ background: "linear-gradient(106.89deg, #304269 50%, #F26101 95%, #D9E8F5 10%)" }}></div>
 */
export default FullApp
