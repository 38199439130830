"use client";
import React from "react";
import { PinContainer } from "./StatsPin";
import hidden from './Images/hidden.jpg';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import focusedteam from './Images/focusedteam.jpg';
import typingfast from './Images/typingfast.jpg';
import handshake from './Images/handshake.jpg';



export default function AnimatedPinDemo() {
  return (
    <div className="sm:h-[500px] w-full flex flex-wrap items-center justify-center gap-8 sm:gap-4">
      {/* Box 1 */}
      <PinContainer title="We aim to always apply for jobs posted within 24 hours - 48 hours to ensure you have the best chances." href="https://zety.com/blog/job-application-tips">
        <div className="flex basis-full flex-col p-2 sm:p-4 tracking-tight text-slate-100/50 sm:basis-1/4 w-[15rem] sm:w-[20rem] h-auto">
          <h3 className="max-w-xs !pb-2 !m-0 font-medium text-lg text-rockethire-orange">8x Probability of Interviewing</h3>
          <div className="text-base !m-0 !p-0 font-normal text-gray-800">
            <span>
              Applying within 24 hours makes you 8x more likely to land the job.
            </span>
          </div>
          <div className="flex flex-1 w-full rounded-lg mt-4 shadow-xl">
            <img src={focusedteam} className="rounded-2xl h-auto border-2 border-gray-700 " alt="Early application advantage"></img>
          </div>
        </div>
      </PinContainer>

      {/* Box 2 */}
      <PinContainer title="RocketHire looks through +30 job boards as well as deep x-ray online to ensure we find the right jobs." href="https://linkedin.com/business/talent/blog">
        <div className="flex basis-full flex-col p-2 sm:p-4 tracking-tight text-slate-100/50 sm:basis-1/4 w-[15rem] sm:w-[20rem] h-auto">
          <h3 className="max-w-xs !pb-2 !m-0 font-medium text-lg text-rockethire-orange">80% Hidden Job Market</h3>
          <div className="text-base !m-0 !p-0 font-normal text-gray-800">
            <span>
              80% of jobs aren’t advertised and are found through x-ray searches.
            </span>
          </div>
          <div className="flex flex-1 w-full rounded-lg mt-4 shadow-xl">
            <img src={typingfast} className="rounded-2xl border-2 border-gray-700" alt="Hidden job market"></img>
          </div>
        </div>
      </PinContainer>

      {/* Box 3 */}
      <PinContainer title="One of our services is to contact the hiring managers directly to ensure that you stand out." href="https://jobscan.co/blog/personalized-outreach">
        <div className="flex basis-full flex-col p-2 sm:p-4 tracking-tight text-slate-100/50 sm:basis-1/4 w-[15rem] sm:w-[20rem] h-auto">
          <h3 className="max-w-xs !pb-2 !m-0 font-medium text-lg text-rockethire-orange">2.5x More Responses</h3>
          <div className="text-base !m-0 !p-0 font-normal text-gray-800">
            <span>
              Personalized outreach gets 2.5x more replies from hiring managers.
            </span>
          </div>
          <div className="flex flex-1 w-full rounded-lg mt-4 shadow-xl">
            <img src={handshake} className="rounded-2xl border-2 border-gray-700 " alt="Personalized outreach"></img>
          </div>
        </div>
      </PinContainer>
    </div>
  );
}
