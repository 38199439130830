import BeamAnimationComponent from '../BeamAnimation/BeamAnimationComponent.tsx'
import WordFadeIn from "../../ui/worldFadeIn.tsx"

const HowItWorks = () => {
    return (
      <div className='bg-[#ffffff00] pt-24'>
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
              <div className='pb-12'>  
                  <WordFadeIn words="How does it work?" />
              </div> 
                <div className='w-[75%] sm:w-[60%] mx-auto flex center'>
                  At WeApply, we make your job search seamless. From resume optimization to job applications,
                  we manage everything so you can focus on preparing for interviews.         
                </div>          
          <div className="grid gap-6 row-gap-10 lg:grid-cols-2 py-4">
            <div className="lg:py-6 lg:pr-16">
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        className="w-4 text-gray-600"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          strokeMiterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        />
                        <polyline
                          fill="none"
                          strokeMiterlimit="10"
                          points="19,15 12,22 5,15"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-300" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold">
                    <span className="text-rockethire-orange">Step 1:</span> Onboarding
                  </p>
                  <p className="text-gray-700">
                  Personalized sessions to understand your goals and create a 
                  tailored success roadmap.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        className="w-4 text-gray-600"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          strokeMiterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        />
                        <polyline
                          fill="none"
                          strokeMiterlimit="10"
                          points="19,15 12,22 5,15"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-300" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold">
                    <span className="text-rockethire-orange">Step 2:</span> Profile Optimization & Account Setup
                  </p>
                  <p className="text-gray-700">
                  Our team enhances your Resume and LinkedIn profile to make you 
                  stand out, while creating necessary accounts.
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        className="w-4 text-gray-600"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <line
                          fill="none"
                          strokeMiterlimit="10"
                          x1="12"
                          y1="2"
                          x2="12"
                          y2="22"
                        />
                        <polyline
                          fill="none"
                          strokeMiterlimit="10"
                          points="19,15 12,22 5,15"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-300" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold">
                    <span className="text-rockethire-orange">Step 3:</span> Job Applications & Updates
                  </p>
                  <p className="text-gray-700">
                    We search for the best opportunities, apply on your behalf, 
                    and provide regular updates.                  
                  </p>
                </div>
              </div>

              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                      <svg
                        className="w-6 text-gray-600"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <polyline
                          fill="none"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          points="6,12 10,16 18,8"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="pt-1">
                  <p className="mb-2 text-lg font-bold">
                    <span className="text-rockethire-orange">Step 4:</span> Interview Preparation & Success
                  </p>
                  <p className="text-gray-700">
                    We provide the tools and resources you need to walk into your
                    interview confident, prepared, and ready to impress.
                  </p>
                  <p className="text-gray-700" />
                </div>
              </div>
            </div>
            <div className="relative flex items-center">
              <BeamAnimationComponent/>
            </div>
          </div>
        </div>
      </div>
    );
};

export default HowItWorks;
