import linkedin from './images/linkedin.png';
import indeed from './images/indeed.png';
import ziprecruiter from './images/ziprecruiter.png';
import angellist from './images/angellist.png';
import glassdoor from './images/glassdoor.png';
import flexjobs from './images/flexjobs.png';
import remote from './images/remote.png';
import monster from './images/monster.png';
import jooble from './images/jooble.png';
import simplyhired from './images/simplyhired.png';


const logos = [
  {
    name: 'LinkedIn',
    src: linkedin,
  },
  {
    name: 'Indeed',
    src: indeed,
  },
  {
    name: 'ZipRecruiter',
    src: ziprecruiter,
  },
  {
    name: 'AngelList',
    src: angellist,
  },
  {
    name: 'Glassdoor',
    src: glassdoor,
  },
  {
    name: 'Jooble',
    src: jooble,
  },
  {
    name: 'Flexjobs',
    src: flexjobs,
  },
  {
    name: 'Remote.co',
    src: remote,
  },
  {
    name: 'Monster',
    src: monster,
  },
  {
    name: 'Stackoverflow',
    src: 'https://res.cloudinary.com/dfhp33ufc/image/upload/v1715276558/logos/ts1j4mkooxqmscgptafa.svg',
  },
  {
    name: 'SimplyHired',
    src: simplyhired,
  },
];

const JobBoards = () => {
  return (
    <div className="w-full py-12">
      <div className="mx-auto w-[80%] sm:w-[50%] px-4 md:px-8">
        <div
          className="group relative mt-6 flex gap-6 overflow-hidden p-2"
          style={{
            maskImage:
              'linear-gradient(to left, transparent 0%, black 20%, black 80%, transparent 95%)',
          }}
        >
          {Array(5)
            .fill(null)
            .map((_, index) => (
              <div
                key={index}
                className="flex shrink-0 animate-logo-cloud flex-row justify-around gap-6"
              >
                {logos.map((logo, key) => (
                  <img
                    key={key}
                    src={logo.src}
                    className="h-10 w-30 px-2 brightness-0 dark:invert"
                    alt={`${logo.name}`}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default JobBoards;
