import { cn } from '../../../lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { Check } from 'lucide-react';
import { useState } from 'react';

const pricingPlans = [
  {
    name: 'Essential Job Search Package',
    description: 'Everything that you need to land an opportunity in the job market.',
    monthlyPrice: 149.99,
    annualPrice: 1650, // Adjust annual price if necessary
    link: 'https://calendly.com/rockethire-coffee/we-apply',
    features: [
      'We apply to up to 45 open jobs per month',
      'Resume creation and optimization',
      'LinkedIn profile improvement',
      'Professional email account setup',
      'Application tracker to monitor progress',
      'Weekly updates on job application status',
    ],
  },
  {
    name: 'Extra Mile Package',
    description: 'We go the extra mile to make you stand out in front of Hiring Managers.',
    monthlyPrice: 249.99,
    annualPrice: 2750, // Adjust annual price if necessary
    link: 'https://calendly.com/rockethire-coffee/we-apply',
    features: [
      'We apply to up to 45 open jobs per month',
      'Resume creation and optimization',
      'LinkedIn profile improvement',
      'Professional email account setup',
      'Application tracker to monitor progress',
      'Weekly updates on job application status',
      'Direct outreach to hiring managers',
      'Tailored cover letters for jobs',
    ],
  },
  {
    name: 'Custom',
    description: 'Tailored solutions for unique job search needs. ',
    monthlyPrice: 'Custom',
    annualPrice: 'Custom',
    link: 'https://calendly.com/rockethire-coffee/we-apply', //book call
    features: [
      'Highly personalized service',
      'Flexible job application limits',
      'Custom resume and LinkedIn enhancements',
      'Dedicated support for unique requirements',
      'Priority updates and insights',
    ],
  },
];

const Pricing = () => {
  const [billingCycle, setBillingCycle] = useState('M');

  const Heading = () => (
    <div className="relative z-10 my-12 flex flex-col items-center justify-center gap-4">
      <div className="flex w-full flex-col items-center justify-center space-y-4">
        <p className="mt-10 text-4xl font-semibold tracking-tight text-black sm:text-5xl dark:text-gray-900 text-center mx-auto">
          Our Prices
        </p>
        <p className="text-md text-gray-700 md:text-center dark:text-gray-800 w-[90%] sm:w-[35%] mx-auto">
          RocketHire takes the hassle out of job hunting, letting you focus on
          what truly matters, <span className="font-bold text-rockethire-orange">acing</span> your
          interviews. From tailored applications to optimized resumes and LinkedIn
          profiles, we handle the legwork so you don't have to.
        </p>
      </div>
    </div>
  );
  
  const PricingCards = () => (
    <div className="relative z-10 mx-auto flex w-full max-w-6xl flex-col gap-8 lg:flex-row lg:gap-4">
      {pricingPlans.map((plan, index) => (
        <div
          key={index}
          className="w-full max-w-[90%] sm:max-w-full mx-auto rounded-xl border-[1px] border-gray-600 p-6 text-left dark:border-gray-600 shadow-xl"
        >
          <p className="mb-1 mt-0 text-m font-semibold uppercase text-rockethire-orange pb-3">
            {plan.name}
          </p>
          <p className="my-0 mb-6 text-sm text-gray-600">{plan.description}</p>
          <div className="mb-8 overflow-hidden">
            <AnimatePresence mode="wait">
              <motion.p
                key={billingCycle === 'M' ? 'monthly' : 'annual'}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ type: 'spring', stiffness: 100 }}
                className="my-0 text-3xl font-semibold text-gray-900 dark:text-gray-900"
              >
                <span>
                  {plan.monthlyPrice === 'Custom'
                    ? 'Custom'
                    : `$${billingCycle === 'M' ? plan.monthlyPrice : plan.annualPrice}`}
                </span>
                <span className="text-sm font-medium">
                  {plan.monthlyPrice === 'Custom'
                    ? ''
                    : `/${billingCycle === 'M' ? 'month' : 'year'}`}
                </span>
              </motion.p>
            </AnimatePresence>
            <a
              href={plan.link}
              className="px-5 py-2.5 relative rounded group overflow-hidden font-medium bg-[#f3e5d881] text-rockethire-orange border border-gray-300 inline-block mt-8 w-full text-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-rockethire-orange group-hover:h-full opacity-90"></span>
              <span className="relative group-hover:text-white">
                {plan.monthlyPrice === 'Custom' ? 'Contact Us' : 'Get Started'}
              </span>
            </a>
          </div>
          {plan.features.map((feature, idx) => (
            <div key={idx} className="mb-3 flex items-center gap-2">
              <Check className="text-rockethire-orange" size={18} />
              <span className="text-sm text-gray-900">{feature}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
  

  return (
    <section className="relative w-full overflow-hidden py-12 text-black lg:px-2 lg:py-12">
      <Heading />
      <PricingCards />
    </section>
  );
};

const BackgroundShift = ({ shiftKey }) => (
  <motion.span
    key={shiftKey}
    layoutId="bg-shift"
    className="absolute inset-0 -z-10 rounded-lg bg-rockethire-orange"
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.8 }}
    transition={{ type: 'spring', stiffness: 200, damping: 20 }}
  />
);

export default Pricing;
