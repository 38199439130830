import RocketHireLogo from "../../assets/rockethirelogo.png";
import { useState, useEffect } from "react";
import { Link as ScrollLink, scroller } from 'react-scroll'; // Import scroller for manual scrolling
import { useNavigate, useLocation } from 'react-router-dom'; // For programmatic navigation
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate(); // Use to navigate programmatically
  const location = useLocation(); // Get the current location (path)

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to handle navigation and scroll to a section
  const handleScrollToSection = (section) => {
    // If the current path is not the homepage, navigate to "/"
    if (location.pathname !== "/") {
      navigate("/"); // Navigate to the main page
      setTimeout(() => {
        // After a small delay to ensure the page has loaded, scroll to the section
        scroller.scrollTo(section, {
          smooth: true,
          offset: -75, // Offset for the navbar height
          duration: 1000,
        });
      }, 100); // Delay to wait for navigation to complete
    } else {
      // If already on the homepage, scroll directly
      scroller.scrollTo(section, {
        smooth: true,
        offset: -75,
        duration: 1000,
      });
    }
  };

  return (
    <div>
      <div className={`fixed w-full z-10 transition-colors duration-300 ${scroll ? 'bg-[#ffffff]' : 'bg-white'}`}>
        <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="relative flex items-center justify-between pl-6 pr-6">
            <a
              href="/"
              aria-label="Company"
              title="Company"
              className="inline-flex items-center"
            >
              <span className="pl-4 text-[50px] font-bold tracking-wide text-[black] uppercase navbar-logo-title ">
                ROCKET<span className="text-[#F26101]">HIRE</span>
              </span>
              <img src={RocketHireLogo} alt="RocketHire logo" className="w-10" />
            </a>
            <ul className="flex items-center hidden space-x-8 lg:flex">

                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "font-medium tracking-wide text-[#F26101] transition-colors duration-200"
                        : "font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                    }
                  >
                    How does hiring work?
                  </NavLink>
                </li>

            {/*
              <li>
              <button
                  onClick={() => handleScrollToSection('stepbystep')}
                  className="font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                >
                  How does hiring work?
                </button>
              </li> */}



                <li>
                  <NavLink
                    to="/pricing"
                    className={({ isActive }) =>
                      isActive
                        ? "font-medium tracking-wide text-[#F26101] transition-colors duration-200"
                        : "font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                    }
                  >
                    Pricing
                  </NavLink>
                </li>

                <li>
                <NavLink
                  to="/faq"
                  className={({ isActive }) =>
                    isActive
                      ? "font-medium tracking-wide text-[#F26101] transition-colors duration-200 "
                      : "font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                  }
                >
                  FAQ
                </NavLink>
              </li>
                    
                <li>
                <NavLink
                  to="/openjobs"
                  className={({ isActive }) =>
                    isActive
                      ? "font-medium tracking-wide text-[#F26101] transition-colors duration-200 "
                      : "font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                  }
                >
                {/* EXTRA OPTION NAVBAR */}
                </NavLink>
              </li>

              <li>
                <a href="https://calendly.com/rockethire-coffee/30min" class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-rockethire-orange transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-white group">
                    <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-rockethire-orange group-hover:h-full"></span>
                    <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                        <svg class="w-5 h-5 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                        <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">Book a Call</span>
                </a>
              </li>
            </ul>
            {/* Mobile menu button */}
            <div className="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full">
                  <div className="p-5 bg-white border rounded shadow-sm">
                    <div className="flex items-center justify-between mb-4">
                      <div>
                        <a
                          href="/"
                          aria-label="Company"
                          title="Company"
                          className="inline-flex items-center"
                        >
                          <img src={RocketHireLogo} alt="RocketHire logo" className="w-6" />
                          <span className="pl-4 text-[50px] font-bold tracking-wide text-[black] uppercase navbar-logo-title">
                            ROCKET<span className="text-[#F26101]">HIRE</span>
                          </span>
                        </a>
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul className="space-y-4">
                        <li>
                          <ScrollLink
                            to="stepbystep"
                            spy={true}
                            smooth={true}
                            offset={-75}
                            duration={1000}
                            className="font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                          >
                            How does hiring work?
                          </ScrollLink>
                        </li>

                        
                          <li>
                            <NavLink
                              to="/pricing"
                              className={({ isActive }) =>
                                isActive
                                  ? "font-medium tracking-wide text-[#F26101] transition-colors duration-200"
                                  : "font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                              }
                            >
                              Pricing
                            </NavLink>
                          </li>

                          <li>
                          <NavLink
                            to="/faq"
                            className={({ isActive }) =>
                              isActive
                                ? "font-medium tracking-wide text-[#F26101] transition-colors duration-200"
                                : "font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                            }
                          >
                            FAQ
                          </NavLink>
                        </li>

                          <li>
                          <NavLink
                            to="/openjobs"
                            className={({ isActive }) =>
                              isActive
                                ? "font-medium tracking-wide text-[#F26101] transition-colors duration-200 "
                                : "font-medium tracking-wide text-[black] transition-colors duration-200 hover:text-[#F26101]"
                            }
                          >
                            {/* EXTRA OPTION NAVBAR */}
                          </NavLink>
                        </li>

                        <li>
                        <a href="https://calendly.com/rockethire-coffee/30min" class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-rockethire-orange transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-white group">
                            <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-rockethire-orange group-hover:h-full"></span>
                            <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                                <svg class="w-5 h-5 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                            </span>
                            <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                            </span>
                            <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">Book a Call</span>
                        </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
