import rocketgif from "../../../assets/gif/rocketgif.gif"
import spacebanner2 from '../../../assets/spacebanner2.jpg'
import { Parallax, ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';



const StepbyStep = () => {
    
    return (
      <ParallaxProvider>
    <Parallax speed={-10}>
      <div className=" relative isolate overflow-hidden  bg-[#ffffff] ">

      <div className='pb-24  sm:pb-12 pt-16'>
        <img
          src={spacebanner2}
          alt="spacebanner"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-[10%]"
        /> 
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 z-10">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 z-10">

          <h2 className=" mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto z-10">
            <span className="relative inline-block">

            </span>{' '}
            How does <span className="text-rockethire-orange">hiring</span> work with us?

          </h2>
          
          <p className="text-base text-black md:text-lg">
          At <span className="navbar-logo-title text-2xl">ROCKET<span className="text-rockethire-orange">HIRE</span></span>, we empower your business to thrive and grow by connecting you with 
          top-tier offshore talent. Our strategic offshore hiring solutions 
          reduce costs, enhance operational flexibility, and open the doors 
          to global expertise, enabling you to focus on core competencies and achieve scalable success.           
          </p>
          
        </div>
        <div className="grid gap-8 row-gap-5 md:row-gap-8 lg:grid-cols-3">
          <div className="p-5 duration-300 transform bg-white border-2 border-dashed rounded shadow-sm border-rockethire-orange hover:-translate-y-2 hover:shadow-xl">
            <div className="flex items-center mb-2">
              <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-rockethire-orange">
                1
              </p>
              <p className="text-lg font-bold leading-5">Discovery Call</p>
            </div>
            <p className="text-sm text-gray-900">
            Tell us what you need and what you're looking for. 
            Lets lay the groundwork to ensure a seamless and effective partnership. 
            </p>
          </div>
          <div className="p-5 duration-300 transform bg-white border-2 border-dashed rounded shadow-sm border-rockethire-orange hover:-translate-y-2 hover:shadow-xl">
            <div className="flex items-center mb-2">
              <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-rockethire-orange">
                2
              </p>
              <p className="text-lg font-bold leading-5">Headhunting</p>
            </div>
            <p className="text-sm text-gray-900">
            RocketHire has a team of headhunters that will vet and bring the best (2-3) candidates in 
            just 5-7 days. All your efforts will go into decision making. 
            </p>
          </div>
          <div className="relative p-5 duration-300 transform bg-white border-2 rounded shadow-sm border-rockethire-orange hover:-translate-y-2 hover:shadow-xl">
            <div className="flex items-center mb-2">
              <p className="flex items-center justify-center w-10 h-10 mr-2 text-lg font-bold text-white rounded-full bg-rockethire-orange">
                3
              </p>
              <p className="text-lg font-bold leading-5">Make your <span className="text-rockethire-orange">RocketHire</span></p>
              <img src={rocketgif} alt="rocketgif" className="absolute z-0  right-8 "/>
            </div>
            <p className="text-sm text-gray-900">
              Just like that, with minimal effort, you have filled your opening in 2-3 weeks! 
            </p>
            <p className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 -mt-4 -mr-4 font-bold rounded-full bg-rockethire-orange sm:-mt-5 sm:-mr-5 sm:w-10 sm:h-10">
              <svg
                className="text-white w-7"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <polyline
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points="6,12 10,16 18,8"
                />
              </svg>
            </p>
          </div>
        </div>
      </div>
      </div>

      </div>
      </Parallax>
      </ParallaxProvider>
    );
  };

export default StepbyStep