
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'

const testimonials = [
  {
    text: "RocketHire made job hunting so much easier. I didn’t have to stress about applications anymore, they handled it all.",
    author: "Marisol Vega",
    image: "https://res.cloudinary.com/dfhp33ufc/image/upload/v1715276558/logos/t2awrrfzdvmg1chnzyfr.svg",
  },
  {
    text: "Constant rejections were tough, but the RH team gave me the confidence to keep going. Knowing someone had my back was great.",
    author: "Étienne Bouchard",
    image: "https://res.cloudinary.com/dfhp33ufc/image/upload/v1715276558/logos/pmblusboe7vkw8vxdknx.svg",
  },
  {
    text: "I had no idea how many things I was doing wrong in my applications until RocketHire stepped in. Their advice changed everything.",
    author: "Anaya Kapoor",
    image: "https://res.cloudinary.com/dfhp33ufc/image/upload/v1715276558/logos/tyos2ayezryjskox3wzs.svg",
  },
  {
    text: "The resume and LinkedIn tweaks RocketHire made? Absolute game-changers. I never thought they mattered so much.",
    author: "Reid Langston",
    image: "https://res.cloudinary.com/dfhp33ufc/image/upload/v1715276560/logos/nymiivu48d5lywhf9rpf.svg",
  },
  {
    text: "They streamlined everything. From resumes to applications, RocketHire saved me hours every week. Totally worth it.",
    author: "Delaney Ward",
    image: "https://res.cloudinary.com/dfhp33ufc/image/upload/v1715276560/logos/nymiivu48d5lywhf9rpf.svg",
  },
];


const TestimonialCarousel = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTestimonial(
        (prevTestimonial) => (prevTestimonial + 1) % testimonials.length,
      )
    }, 3500) // Change Time here

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const { text, author, image } = testimonials[currentTestimonial]

  const variants = {
    initial: { opacity: 0, y: '100%', scale: 0.1 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, y: '100%', scale: 0.1 },
  }
  const dotVariants = {
    active: { scale: 1.2, backgroundColor: '#3f3f46' },
    inactive: { scale: 1, backgroundColor: '#D1D5DB' },
  }

  return (
        <section className="flex items-center justify-center pb-12 md:pb-24 md:pt-4">
        <div className="w-[80%] sm:w-full max-w-2xl">
            <AnimatePresence mode="popLayout">
            <motion.div
                key={currentTestimonial}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants}
                className="flex w-full flex-col items-center justify-center"
                transition={{
                type: 'spring',
                stiffness: 200,
                damping: 20,
                duration: 0.5,
                }}
            >
              
               {/* <img src={image} alt={author} className="m-0 h-24 w-24" />*/}
                <p className="m-0 text-center text-2xl font-medium tracking-tight">
                &quot;{text}&quot;
                </p>
                <div className="mx-auto mt-5">
                <div className="flex flex-col items-center justify-center space-x-3">
                    <div className="font-regular text-sm text-gray-900/80">
                    {author}
                    </div>
                </div>
                </div>
            </motion.div> 
            <div className="mt-8 flex justify-center">
                {testimonials.map((_, index) => (
                <motion.div
                    key={index}
                    className="mx-1 h-1 w-1 cursor-pointer rounded-full"
                    variants={dotVariants}
                    animate={index === currentTestimonial ? 'active' : 'inactive'}
                    onClick={() => setCurrentTestimonial(index)}
                />
                ))}
            </div>
            </AnimatePresence>
        </div>
        </section>
  )
}

export default TestimonialCarousel
