import rocketHireLogo from "../../assets/rockethirelogo.png";

const Footer = () => {

    return (
      
      <div className="bg-[#ffffff]">
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
            <div className="sm:col-span-2">
              <div className="flex flex-col items-center text-center">
                <a href="/" aria-label="Go home" title="Company" className="inline-flex items-center">
                <span class="pl-4 text-4xl font-bold tracking-wide text-[black] uppercase navbar-logo-title ">
                  ROCKET<span className="text-[#F26101]">HIRE</span>
                </span>
                <img src={rocketHireLogo} alt="RocketHire logo" className="w-8"/>
                </a>
                <div className="mt-6 lg:max-w-sm">
                  <p className="text-sm text-gray-800">
                  We simplify job hunting by handling tedious tasks like applying to
                   jobs and optimizing resumes, letting you focus on preparing for 
                   interviews and landing your dream role.
                  </p>
                </div>
              </div>
            </div>
            <div className="space-y-2 text-sm flex flex-col items-center">
              <p className="text-base font-bold tracking-wide text-gray-900">
                Contacts
              </p>
              <div className="flex flex-col items-center space-y-2">
                <div className="flex items-center">
                  <p className="mr-1 text-gray-800">Phone:</p>
                  <a href="tel:613-927-3211" className="transition-colors duration-300 text-rockethire-orange hover:text-black">
                    613-927-3211
                  </a>
                </div>
                <div className="flex items-center">
                  <p className="mr-1 text-gray-800">Email:</p>
                  <a href="mailto:info@rockethire.ca" className="transition-colors duration-300 text-rockethire-orange hover:text-black">
                    info@rockethire.ca
                  </a>
                </div>
                <div className="flex items-center">
                  <p className="mr-1 text-gray-800">Address:</p>
                  <a href="https://www.google.com/maps/place/305+King+St+W,+Kitchener,+ON+N2G+1B9,+Canada/@43.4515515,-80.4975774,1101m/data=!3m2!1e3!4b1!4m6!3m5!1s0x882bf4f4be31e5ed:0x48cac6ce8f154249!8m2!3d43.4515476!4d-80.4950025!16s%2Fg%2F11bw3xrq8r?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D" 
                  target="_blank" rel="noopener noreferrer" className="transition-colors duration-300 text-rockethire-orange hover:text-black">
                    305 King St W, ON, CA
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center text-center">
              <span className="text-base font-bold tracking-wide text-gray-900">Social</span>
              <div className="flex items-center mt-1 space-x-3">
              <div className="flex items-center mt-1 space-x-3">
            {/*<a
              href="/"
              className="text-gray-500 transition-colors duration-300 hover:text-rockethire-orange"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
              </svg>
            </a>*/}
            <a
              href="https://calendly.com/rockethire-coffee/30min"
              className="text-gray-500 transition-colors duration-300 hover:text-rockethire-orange"
            >
              <svg className="fill-gray-500 duration-300 hover:fill-rockethire-orange" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="21" height="21" viewBox="0 0 50 50"> <path d="M2 18L2 32 12 32 12 18zM39 9v4.31l-10 9V16H14V6h22C37.66 6 39 7.34 39 9zM29 27.69l10 9V41c0 1.66-1.34 3-3 3H14V34h15V27.69zM12 34v10H5c-1.657 0-3-1.343-3-3v-7H12zM12 6L12 16 2 16zM29 25L39 16 39 34zM49 9.25v31.5c0 .87-1.03 1.33-1.67.75L41 35.8V14.2l6.33-5.7C47.97 7.92 49 8.38 49 9.25z"></path> </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/102769525/admin/feed/posts/"
              className="text-gray-500 transition-colors duration-300 hover:text-rockethire-orange"
            >
            <svg className="fill-gray-500 duration-300 hover:fill-rockethire-orange" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50"> <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path> </svg>
            
            </a>
          </div>
              </div>
              <p className="mt-4 text-sm text-gray-500">Don't hesitate to contact us if you'd like to know more about our services.</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-5 pb-10 border-t lg:flex-row lg:justify-between">
            <p className="text-sm text-gray-600">© Copyright 2024 RocketHire Inc. All rights reserved.</p>
            <ul className="flex space-x-5">
              <li><a href="/" className="text-sm text-gray-600 transition-colors duration-300 hover:text-rockethire-orange">F.A.Q</a></li>
              {/* More links can be uncommented here */}
            </ul>
          </div>
        </div>
      </div>
    );
};

export default Footer;